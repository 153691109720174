<template>
  <div class="audio">
    <audio
      class="align-center"
      ref="audio"
      tabindex="-1"
      @pause="pauseing"
      @playing="playing"
      @error="error"
      @timeupdate="timeupdate"
      @ended="ended"></audio>
    <div class="audioController">
      <div class="c_head page-item">
        <i class="iconfont1 back" @click="exit">&#xe6ff;</i>
        <h2 id="playTitle">
          {{ program_name }}
        </h2>
      </div>
      <div class="audio_style">
        <div>
          <div v-show="showError"><p v-html="errorTip"></p></div>
          <div class="controls">
          <div class="ytp-chrome-controls">
          <div class="ytp-left-controls">
            <div class="ytp-button" @click="player">
              <i class="iconfont1" v-html="playIcon"></i>
            </div>
            <div class="ytp-time-display">
              <span>
                <span class="ytp-time-current">{{
                  current | timeFormate
                }}</span>
                <span class="ytp-time-separator"> / </span>
                <span class="ytp-time-duration">{{
                  duration | timeFormate
                }}</span>
              </span>
            </div>
          </div>
          <my-slider
          bgColor="gray"
          :initNum="playRate"
          @on-change="skipe"
          @on-skipe="skipe"
          @on-input="onInput"
          class="process"
        />
          <div class="ytp-right-controls">
            <div
              tabindex="0"
              @mouseenter="showVolume = true"
              @mouseleave="showVolume = false"
              @focus="showVolume = true"
              @blur="showVolume = false"
              style="display: -webkit-flex"
              :class="showVolume ? 'enter' : ''"
            >
              <div v-show="showVolume"
                class="volume">
                <my-slider
                :initNum="getVolume"
                bgColor="gray"
                @on-change="setVolume"
                @on-skipe="setVolume"
              />
              </div>
              <div class="ytp-button">
                <i class="iconfont1" v-html="volumeIcon"></i>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
function PrefixInteger (num, length) {
  return (Array(length).join('0') + num).slice(-length)
}
export default {
  props: {
    program_name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      audio: null,
      showError: false,
      errorTip: '',
      duration: 0,
      current: 0,
      playState: false,
      volume: 0,
      showVolume: false,
      seeking: false
    }
  },
  computed: {
    playIcon () {
      return this.playState ? '&#xea81;' : '&#xe87c;'
    },
    playRate () {
      return this.duration === 0 ? 0 : (this.current / this.duration) * 100
    },
    getVolume () {
      return this.volume * 100
    },
    volumeIcon () {
      return this.volume > 0 ? '&#xeca5;' : '&#xe65e;'
    }
  },
  watch: {
    volume (newVal) {
      this.audio.volume = newVal
    }
  },
  mounted () {
    this.audio = this.$refs.audio
    this.volume = this.audio.volume
  },
  filters: {
    timeFormate: function (value) {
      value = Math.ceil(value)
      let r = ''
      const h = PrefixInteger(parseInt(value / 3600), 2)
      const m = PrefixInteger(parseInt((value % 3600) / 60), 2)
      const S = PrefixInteger((value % 3600) % 60, 2)
      if (h !== '00') {
        r += h + ':'
      }
      r += m + ':'
      r += S
      return r
    }
  },
  methods: {
    setVolume (volume) {
      this.volume = volume / 100
    },
    player () {
      if (this.audio.paused) {
        this.play()
      } else {
        this.pause()
      }
    },
    play (url) {
      if (url) {
        this.audio.src = url
      }
      this.audio.play()
      this.setVolume(80)
    },
    exit () {
      this.audio.pause()
      this.audio.src = ''
      this.$emit('exit')
    },
    pauseing () {
      console.log('pauseing')
      this.playState = false
    },
    pause () {
      this.audio.pause()
    },
    playing () {
      this.playState = true
    },
    onInput () {
      this.seeking = true
    },
    skipe (num) {
      console.log(num)
      this.seeking = false
      num = (num * this.duration) / 100
      this.seek(num)
    },
    seek (num) {
      console.log(num)
      this.play()
      this.audio.currentTime = num
    },
    loadstart: function () {
      this.duration = 0
      this.current = 0
    },
    timeupdate: function () {
      if (this.seeking) return
      this.current = this.audio.currentTime
      this.duration = this.audio.duration
    },
    ended () {
      this.$emit('ended')
    },
    showTip (msg) {
      this.errorTip = msg
      this.showError = true
    },
    error () {
      if (
        !this.audio ||
        this.audio.src === '' ||
        this.audio.src === location.href
      ) { return }
      this.$emit('error', this.audio.error.code)
    }
  }
}
</script>
<style lang="scss" scoped>
.audio{
  height: 100%;
  .audioController{
    height: 100%;
    .c_head {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      /* padding: 0 30px; */
      display: -webkit-flex;
      #playTitle {
        line-height: 50px;
        font-size: 24px;
      }
    }

    .back {
      font-size: 40px;
      margin: 0 30px;
      line-height: 50px;
    }

    .audio_style{
      display: -webkit-flex;
      -webkit-justify-content: center;
      -webkit-align-items: center;
      height: 100%;
      .controls {
        font-size: 15px;
        border: 1px solid;
        border-radius: 50px;
        background: #535050;
        position: relative;
        .process{
          margin: auto 25px;
          width: 600px;
          padding-top: 20px;
        }
        .ytp-chrome-controls {
          height: 78px;
          line-height: 78px;
          display: -webkit-flex;
          -webkit-flex: 1;
          margin-top: 10px;
        }

    .ytp-left-controls {
      display: -webkit-flex;
      -webkit-flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

      .ytp-button {
        width: 58px;
        padding: 0 2px;
        font-size: 30px;
        text-align: center;
        /* line-height: 57px; */
        cursor: pointer;
      }

      .ytp-time-display {
        padding: 0 5px;
        white-space: nowrap;
      }

      .volume {
        width: 100px;
        /* height: 40px; */
        position: absolute;
        top: -40px;
        background: wheat;
        border-radius: 20px;
        right: 0px;
        padding:20px 20px 0px 20px
      }
      }

    }
  }

}

</style>
