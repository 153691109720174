<template>
  <div
    class="player"
    style="display: block"
    tabindex="1"
    @keydown="keydown($event)"
  >
    <my-video
      v-if="currentPlayObject.type === 'video'"
      ref="video"
      :program_name="currentPlayObject.title"
      @error="error"
      @exit="exit"
      @ended="ended"
      @prev="prev"
      @next="next"
      @getIsLoading="getIsLoading"
    />
    <my-audio
      v-else
      ref="audio"
      :program_name="currentPlayObject.title"
      @error="error"
      @exit="exit"
      @ended="ended"
    />
  </div>
</template>
<script>
import myVideo from '@/components/video.vue'
import myAudio from '@/components/audio.vue'
import { sendCommand } from '@/util/index.js'
import { keyMap } from '@/util/keyUtil.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  components: { myVideo, myAudio },
  data () {
    return {
      player: null,
      audioList: this.$route.params.mediaList.currentDirAudio,
      videoList: this.$route.params.mediaList.currentDirVideo,
      currentPlayObject: this.datas,
      currentPlayIndex: 0,
      isLoading: false
    }
  },
  mounted () {
    console.log(this.audioList)
    document.getElementsByClassName('player')[0].focus()
    if (this.datas.type === 'video') {
      this.player = this.$refs.video
      this.currentPlayIndex = this.videoList.findIndex((item) => {
        return this.currentPlayObject.title === item.title
      })
    } else {
      this.player = this.$refs.audio
      this.currentPlayIndex = this.audioList.findIndex((item) => {
        return this.currentPlayObject.title === item.title
      })
    }
    this.play()
  },
  methods: {
    keydown (e) {
      const key = keyMap[e.keyCode]
      switch (key) {
        case 'return':
          this.player.exit()
          break
        case 'ok':
          this.player.player()
          break
        case 'up':
          console.log('get up')
          if (this.isLoading) {
          } else {
            this.prev()
          }
          break
        case 'down':
          console.log('get down')
          if (this.isLoading) {
          } else {
            this.next()
          }
          break
      }
    },
    play () {
      this.player.play(this.datas.url)
    },
    error (code) {
      console.log('error.code:' + code)
      switch (code) {
        case 3:
          // 解码错误
          this.player.showTip('Exception occurred during playback')
          break
        case 4:
          this.player.showTip('Exception occurred during playback')
          break
      }
    },
    ended () {},
    exit () {
      sendCommand('hdmi_static')
      this.$router.push({
        name: 'usb',
        params: {
          dir: this.datas.dir
        }
      })
    },
    getIsLoading (state) {
      this.isLoading = state
    },
    prev () {
      console.log('Prev')
      if (this.currentPlayIndex <= 0) {
        this.currentPlayIndex = 0
        return
      } else {
        this.currentPlayIndex -= 1
      }
      if (this.currentPlayObject.type === 'video') {
        this.currentPlayObject = this.videoList[this.currentPlayIndex]
      } else if (this.currentPlayObject.type === 'audio') {
        this.currentPlayObject = this.audioList[this.currentPlayIndex]
      }
      this.player.play(this.currentPlayObject.url)
    },
    next () {
      console.log('Next')
      if (this.currentPlayObject.type === 'video') {
        if (this.currentPlayIndex >= this.videoList.length - 1) {
          return
        } else {
          this.currentPlayIndex += 1
        }
        this.currentPlayObject = this.videoList[this.currentPlayIndex]
      } else if (this.currentPlayObject.type === 'audio') {
        if (this.currentPlayIndex >= this.audioList.length - 1) {
          return
        } else {
          this.currentPlayIndex += 1
        }
        this.currentPlayObject = this.audioList[this.currentPlayIndex]
      }
      this.player.play(this.currentPlayObject.url)
    }
  }
}
</script>
<style lang="scss" scoped>
.player {
  color: white;
  height: 100%;
}
</style>
